import logo from './logo.svg';
import './App.css';
import Ape from './CivilWarApe.png';

import styled from 'styled-components';

const AppBackground = styled.main`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  min-height: 100%;
  min-width: 100%;
  background-color: #0B0B14;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const CivilWarApe = styled.img`
  max-width: 400px;
`

const CivilWarText = styled.h1`
  font-family: Propaganda;
  color: white;
  font-size: 25px;
  text-align: center;
`

const SubText = styled.h2`
  font-size: 18px;
  font-family: Propaganda;
  color: white;
  text-align: center;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
`

function App() {
  return (
    <AppBackground>
      <CivilWarApe 
        src={Ape}
        alt='The War is only beginning.'
      />
      <CivilWarText>
         Ape War Council
      </CivilWarText>
      <SubText>
        Coming Soon
      </SubText>
    </AppBackground>
  );
}

export default App;
